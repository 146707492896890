<template>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav
        class="
          navbar navbar-expand-md navbar-dark
          px-0
          pr-sm-3
          navbar-offcanvas
        "
      >
        <router-link
            to="/jual/pesanan-group"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
          <i class="fas fa-arrow-left"></i>
        </router-link>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
            mr-0 mr-md-5
          "
        >
          <span class="h6 font-weight-bold text-truncate">Lisensi</span>
        </span>
        <Menu>
          <ul class="navbar-nav w-100 pl-3 pl-md-0">
            <li class="nav-item mt-1 mb-n1 active">
              <router-link
                to="/jual/lisensi"
                class="nav-link bg-yellowish text-main"
                title="Lisensi (Free trial 5 hari lagi)"
                >Lisensi<span class="d-md-none">
                  (Free trial 5 hari lagi)</span
                ></router-link>
            </li>
            <li class="nav-item mt-1 mb-n1">
              <router-link to="/jual/bantuan" class="nav-link" title="Chat"
                >Bantuan</router-link>
            </li>
          </ul>
        </Menu>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">
        <div class="row mt-3">
          <div class="col-12">
            <div
              class="alert alert-danger bg-light alert-dismissible fade show"
              role="alert"
            >
              <button type="button" class="close" data-dismiss="alert">
                &times;
              </button>
              <p class="mb-2">
                <strong>5 hari lagi</strong> masa trial 30-hari akan berakhir.
                Silakan masukkan kode lisensi atau kode promo untuk melanjutkan.
              </p>
              <p class="small mb-0">
                Data anda akan kami pertahankan selama 30-hari setelah masa
                trial berakhir.
              </p>
              <!--<button id="submitButton" class="btn btn-sm btn-light border py-0 mt-n1" type="submit" title="Action..."><i class="fa fa-user mr-1"></i>Action</button>-->
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <div class="row">
          <div class="col-12 col-md-6">
            <p class="small mb-1">Jenis Lisensi Aktif</p>
            <p class="text-main mb-3">
              Masa trial 30-hari
              <span class="text-muted">(5-hari lagi berakhir)</span>
            </p>
          </div>
          <!-- .col -->
          <div class="col-12 col-md-6">
            <p class="small mb-1">Level Pengguna</p>
            <p class="text-main">Standar</p>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
        <div class="row mb-5">
          <div class="col-12">
            <form
              action="./jual-pesanan-customer.html"
              class="needs-validation"
              novalidate
            >
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="kode"
                      >Kode: <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="kode"
                      placeholder="Masukkan kode lisensi/promo..."
                      name="kode"
                      value=""
                      required
                      autofocus
                    />
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Isian ini wajib diisi.</div>
                  </div>
                </div>
                <!-- .col-* -->
              </div>
              <!-- .row -->

              <hr class="no-side-margin mt-1" />

              <div class="row mt-3">
                <div class="col text-center">
                  <button
                    id="submitButton"
                    type="submit"
                    class="btn btn-main"
                    title="Aktifkan"
                  >
                    <i class="fa fa-send mr-1"></i>Aktifkan
                  </button>
                  <button
                    type="reset"
                    class="btn btn-light"
                    title="Reset isian"
                  >
                    <i class="fa fa-refresh mr-1"></i>Reset
                  </button>
                  <a
                    href="./jual-pesanan.html"
                    class="btn btn-light"
                    onclick="confirm('Anda yakin meninggalkan form isian ini?\nPerubahan tidak akan disimpan.');"
                    title="Batal"
                    ><i class="fa fa-remove mr-1"></i>Batal</a
                  >
                </div>
                <!-- .col-* -->
              </div>
              <!-- .row -->
            </form>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</template>
<script>
import Menu from "../../components/menu/Menu.vue";
export default {
  setup() {},
  components: {
    Menu,
  },
};
</script>